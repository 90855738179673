import React, { useLayoutEffect, useMemo, useState } from 'react';
import { KiteAlert } from '@kite/react-kite';
import {
    matchPath,
    parseMessageHTML,
    useCompanyBannerQuery,
} from './CompanyBannerUtils';
import { bannerTypeMap } from './CompanyBannerTypes';
import { SmartLink } from '../SmartLink/SmartLink';
import { isBrowser } from '../../shared/isBrowser';
import './CompanyBanner.scss';

export const CompanyBanner = ({
    pathname = ``,
}: {
    pathname: string;
}): React.ReactNode => {
    const [isOpen, setIsOpen] = useState(false);
    const bannerData = useCompanyBannerQuery();
    const activeBanner = bannerData.nodes.find((banner) => banner.status);
    if (!activeBanner) return;

    const {
        dismissible: isDismissible,
        field_banner_button: isButton,
        field_banner_link: link,
        field_banner_type: type,
        limit_to_pages: pageLimits,
        limit_to_pages_negate: isNegatePages,
        message,
    } = activeBanner;

    const linkVariant = isButton ? `button` : `link`;

    const isPathMatch = useMemo(() => {
        if (pathname) {
            return matchPath(pathname, pageLimits);
        }
        return false;
    }, [pathname, pageLimits]);

    const parsedHTML = parseMessageHTML(message);

    useLayoutEffect(() => {
        // Don't show on these pages
        if ([`/404`, `/search`].includes(pathname)) {
            setIsOpen(false);
            return;
        }

        // Checks if the banner has been closed in this session already
        if (isBrowser && window.sessionStorage.getItem(`banner-closed`)) {
            setIsOpen(false);
            return;
        }

        // Flips the logic to show the banner if the page is negated
        if (isNegatePages) {
            setIsOpen(!isPathMatch);
            return;
        }
        // Shows the banner on page match
        setIsOpen(isPathMatch);
    }, [isNegatePages, isPathMatch, pathname]);

    // This routes the Kite Alert link and button clicks through a <SmartLink /> component to pick up analytics and add internal / external linking logic
    const handleLinkClick = () => {
        if (isBrowser) {
            const link = document.querySelector<HTMLAnchorElement>(
                `.company-banner__hidden-link`
            );
            if (link) {
                link.click();
            }
        }
    };

    const handleClose = () => {
        if (isBrowser) {
            window.sessionStorage.setItem(`banner-closed`, `true`);
        }
        setIsOpen(false);
    };

    if (!bannerData) return;

    return (
        <>
            {isOpen && (
                <div className="company-banner">
                    <KiteAlert
                        type={bannerTypeMap[type]}
                        level="global"
                        linkText={link?.title}
                        linkVariant={linkVariant}
                        onLinkClick={handleLinkClick}
                        onClose={handleClose}
                        linkUrl={undefined}
                        dismissible={isDismissible}
                        autoFocus={false}
                    >
                        {parsedHTML}
                        {/** Hidden Smart Link that is programmatically clicked for analytics and linking logic */}
                        <SmartLink
                            url={link?.uri}
                            classNames={[`company-banner__hidden-link`]}
                            newWindow
                            children={undefined}
                            hide
                        />
                    </KiteAlert>
                </div>
            )}
        </>
    );
};
