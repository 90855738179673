import React, { useEffect } from 'react';
import './PolicyHeader.scss';
import FocusTrapReact from 'focus-trap-react';
import { SearchInput } from '../SearchInput/SearchInput';
import { useSearchContext } from '../../../providers/SearchProvider';
import { SearchResults } from '../../SearchResults/SearchResults';
import { SearchTrigger } from '../SearchTrigger/SearchTrigger';
import { SidebarTrigger } from '../SidebarTrigger/SidebarTrigger';
import { processLink } from '../../../shared/processLink';
import { graphql, Link, useStaticQuery } from 'gatsby';

interface PolciyHeaderProps {
    sidebarOpen: boolean;
    setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
    searchOpen: boolean;
    setSearchOpen: React.Dispatch<React.SetStateAction<boolean>>;
    searchInline?: boolean;
    refHeaderHeight?: number;
}

export const PolicyHeader = ({
    sidebarOpen,
    setSidebarOpen,
    searchOpen,
    setSearchOpen,
    searchInline,
    refHeaderHeight,
}: PolciyHeaderProps) => {
    const { loadSearch } = useSearchContext();

    const menuData = useStaticQuery(graphql`
        query {
            allMenuItems(filter: { menu_name: { eq: "public-policy" } }) {
                nodes {
                    title
                    menu_name
                    id
                    url
                    weight
                    childrenMenuItems {
                        id
                        title
                        weight
                        url
                    }
                }
            }
        }
    `);

    const navItems = menuData.allMenuItems.nodes;

    if (navItems) {
        navItems.forEach((navItem) => {
            navItem.url = processLink(navItem.url, true);
        });
    }

    useEffect(() => {
        if (searchInline) {
            setSearchOpen(true);
        }
    }, [searchInline]);

    useEffect(() => {
        loadSearch();
    }, []);

    return (
        <header className="policy-header nav-header">
            <FocusTrapReact
                active={searchOpen && !searchInline}
                focusTrapOptions={{
                    allowOutsideClick: true,
                    clickOutsideDeactivates: true,
                    initialFocus: `#inputSearch`,
                }}
            >
                <section>
                    <div className="kite-container nav">
                        {!searchOpen && (
                            <h2>
                                <Link to="/">Public Policy</Link>
                            </h2>
                        )}
                        {!searchOpen && (
                            <SidebarTrigger
                                sidebarOpen={sidebarOpen}
                                setSidebarOpen={setSidebarOpen}
                                searchOpen={searchOpen}
                            />
                        )}
                        {!searchOpen && (
                            <nav>
                                <ul>
                                    {!!navItems &&
                                        navItems.map((navItem, idx) => (
                                            <li key={`policy-nav-${idx}`}>
                                                <Link
                                                    to={navItem.url}
                                                    activeClassName="active"
                                                    partiallyActive={
                                                        navItem.url !== `/`
                                                    }
                                                >
                                                    {navItem.title}
                                                </Link>
                                            </li>
                                        ))}
                                </ul>
                            </nav>
                        )}
                        {!searchOpen && (
                            <SearchTrigger setSearchOpen={setSearchOpen} />
                        )}

                        {searchOpen && (
                            <SearchInput
                                searchOpen={searchOpen}
                                setSearchOpen={setSearchOpen}
                                searchInline={searchInline}
                            />
                        )}
                    </div>
                    {searchOpen && !searchInline && (
                        <div className="search-results">
                            <SearchResults
                                searchInline={searchInline}
                                setSearchOpen={setSearchOpen}
                                setSidebarOpen={setSidebarOpen}
                            />
                        </div>
                    )}
                </section>
            </FocusTrapReact>
        </header>
    );
};
