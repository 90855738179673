import { KiteIcon, KiteProgressIndicator } from '@kite/react-kite';
import React, { useState, useRef, FC } from 'react';
import { navigate } from 'gatsby';
import { useContext } from 'react';
import { useEffect } from 'react';
import {
    useSearchContext,
    SearchContext,
} from '../../../providers/SearchProvider';
import { AnalyticsController } from '../../Analytics';
import './SearchInput.scss';
import { flushSync } from 'react-dom';
import { isBrowser } from '../../../shared/isBrowser';

interface Props {
    searchInline?: boolean;
    searchOpen: boolean;
    setSearchOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SearchInput: FC<Props> = ({
    searchInline,
    searchOpen,
    setSearchOpen,
}: Props) => {
    const inputSearchRef = useRef(null);

    useEffect(() => {
        // Ensures the input receives focus on open
        setTimeout(() => {
            inputSearchRef.current?.focus();
        }, 30);
    }, []);

    const handleFocus = () => {
        inputSearchRef.current.focus();
    };

    const searchContext = useContext(SearchContext);
    const {
        query,
        searchValid,
        searchResults,
        searchFocused,
        loadSearch,
        loading,
        searchHandler,
        searchFocusedHandler,
    } = useSearchContext();

    const handleChange = (event) => {
        searchContext.searchHandler(event.target.value);
    };

    const handleClear = () => {
        inputSearchRef.current.value = ``;
    };

    const handleSubmit = (evt) => {
        searchHandler(inputSearchRef.current.value);
        navigate(`/search?q=${inputSearchRef.current.value}`);
        setSearchOpen(false);
    };

    const handleCloseSearch = (e: React.MouseEvent) => {
        e.preventDefault();
        flushSync(() => setSearchOpen(false));
        setVisible(``);
        searchContext.searchHandler(query);

        if (isBrowser) {
            const trigger: HTMLButtonElement =
                document.querySelector(`.search-trigger`);
            if (trigger) {
                trigger.focus();
            }
        }
    };

    const [visible, setVisible] = useState(``);
    useEffect(() => {
        setTimeout(() => {
            setVisible(`visible`);
        }, 1);
    }, [setVisible]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (!query) return;
            AnalyticsController.track({
                event: `search`,
                searchType: `global`,
                searchTerm: query,
            });
        }, 500);

        return () => {
            clearTimeout(timer);
        };
    }, [query]);

    return (
        <form
            className={`search__header ${visible}`}
            aria-hidden={!searchOpen}
            onSubmit={(event) => {
                handleSubmit(event);
            }}
        >
            <KiteIcon icon="ki-search" size="1.75rem" className="search-icon" />

            <input
                id="inputSearch"
                ref={inputSearchRef}
                disabled={!searchOpen}
                onFocus={() => {
                    searchContext.searchFocusedHandler();
                }}
                aria-label="Search input field"
                value={searchContext.query}
                onChange={handleChange}
                onLoadedData={handleFocus}
                onClick={handleFocus}
                onLoad={handleFocus}
                placeholder="Search"
                name="search"
            ></input>

            {searchFocused && searchValid && loading && (
                <KiteProgressIndicator
                    className="loader"
                    id="searchLoader"
                    size="sm"
                />
            )}
            {searchValid && (
                <button
                    onClick={handleClear}
                    aria-label="Remove search query term"
                    type="button"
                >
                    <KiteIcon icon="ki-backspace" size="1.375rem" />
                </button>
            )}
            <button
                disabled={!searchOpen}
                onClick={handleCloseSearch}
                aria-label="Hide the search bar"
                type="button"
            >
                <KiteIcon icon="ki-x" size="1.5rem" />
            </button>
        </form>
    );
};
