export type AdjacentIndexes = {
    previousIndex: number;
    nextIndex: number;
};

/**
 * The getAdjacentIndexes function calculates the previous and next indexes in a circular list.
 * It takes the current index and the array, and returns an object containing the previous and next indexes.
 * If the current index is the first index, the previous index wraps around to the last index.
 * If the current index is the last index, the next index wraps around to the first index.
 * If the array is empty or the current index is negative, it returns default values of -1 for both indexes.
 *
 * @template T - The type of elements in the array.
 * @param {T[]} arr - The array of elements.
 * @param {number} currentIndex - The current index in the array. Must be a non-negative integer.
 * @returns {AdjacentIndexes} An object containing the previous and next indexes.
 * @throws Will throw an error if the currentIndex is negative or if the array is empty.
 *
 * @example
 * const arr = [1, 2, 3, 4, 5];
 * const indexes = getAdjacentIndexes(arr, 2);
 * console.log(indexes); // { previousIndex: 1, nextIndex: 3 }
 */
export function getAdjacentIndexes<T>(
    arr: T[],
    currentIndex: number
): AdjacentIndexes {
    const adjacentIndexes: AdjacentIndexes = {
        previousIndex: -1,
        nextIndex: -1,
    };
    const totalElements: number = arr?.length;
    if (!totalElements || currentIndex < 0) {
        return adjacentIndexes;
    }

    adjacentIndexes.previousIndex =
        currentIndex === 0 ? totalElements - 1 : currentIndex - 1;

    adjacentIndexes.nextIndex =
        currentIndex >= totalElements ? 0 : (currentIndex + 1) % totalElements;

    return adjacentIndexes;
}

export function isLastIndex(
    currentIndex: number,
    totalElements: number
): boolean {
    return totalElements - 1 === currentIndex;
}
