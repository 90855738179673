import { TDataNodes } from '../../shared/types';

export type TSiteWideAlertData = TDataNodes<
    `allSitewideAlertSitewideAlert`,
    IAlert
>;

export const bannerTypeMap = {
    negative_red: `error`,
    caution_yellow: `caution`,
    neutral_blue: `info`,
};

export interface IAlert {
    dismissible: boolean;
    field_banner_button: boolean;
    field_banner_link: {
        title: string;
        uri: string;
    };
    field_banner_type: string;
    limit_to_pages: string;
    limit_to_pages_negate: boolean;
    message: {
        value: string;
    };
    name: string;
    status: boolean;
}
