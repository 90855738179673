import { Link } from 'gatsby';
import React, { useRef } from 'react';
import { MenuItem } from '../../../models/MenuItem';
import { useLayoutContext } from '../../../providers/LayoutProvider';
import { KeyboardKey } from '../../../shared/enums/keyboardKeys.enum';
import {
    AdjacentIndexes,
    getAdjacentIndexes,
    isLastIndex,
} from '../../../shared/getAdjacentIndexes';
import { processLink } from '../../../shared/processLink';
import { AnalyticsController } from '../../Analytics';
import './MainMenuSubMenu.scss';

interface Props {
    ariaHidden?: boolean;
    items: MenuItem[];
    footer?: boolean;
    title: string;
    titleId: string;
    setSidebarOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    handleKeyDown?: (e: React.KeyboardEvent) => void;
    handleClose?: () => void;
}
export const MainMenuSubMenu = ({
    ariaHidden,
    items,
    setSidebarOpen,
    footer,
    title,
    titleId,
    handleClose,
    handleKeyDown,
}: Props): React.ReactNode => {
    const { isPolicy } = useLayoutContext();
    const linksRef = useRef([]);

    const onClick = (title: string) => {
        if (typeof window !== `undefined`) {
            (window.document.activeElement as HTMLElement).blur();
        }
        if (setSidebarOpen) {
            setSidebarOpen(false);
        }
        if (!footer) {
            AnalyticsController.track({
                event: `global header click`,
                globalHeaderLink: title,
            });
        } else {
            AnalyticsController.track({
                event: `global footer click`,
                globalFooterLink: title,
            });
        }
    };

    const handleSubMenuKeyDown = (
        event: React.KeyboardEvent,
        idx: number,
        items: MenuItem[]
    ) => {
        const { key, shiftKey } = event;

        if (key === KeyboardKey.ArrowUp || key === KeyboardKey.ArrowDown) {
            event.preventDefault();
        }
        const adjacentIndexes: AdjacentIndexes = getAdjacentIndexes(items, idx);

        if (
            (isLastIndex(idx, items.length) && key === KeyboardKey.Tab) ||
            (idx == 0 && key === KeyboardKey.Tab && shiftKey)
        ) {
            handleClose?.();
            return;
        } else if (key === KeyboardKey.ArrowUp) {
            linksRef.current[adjacentIndexes.previousIndex].focus();
        } else if (key === KeyboardKey.ArrowDown) {
            linksRef.current[adjacentIndexes.nextIndex].focus();
        }
        handleKeyDown(event);
    };

    return (
        <ul
            id={titleId}
            aria-label={title}
            aria-hidden={ariaHidden}
            role="menu"
        >
            {items
                .sort((a, b) => parseFloat(a.weight) - parseFloat(b.weight))
                .filter((item) => item.url)
                .map((item, idx) => {
                    const processedUrl = processLink(
                        item.url,
                        isPolicy,
                        undefined,
                        item.menu_name.includes(`policy`) ? `ga` : `cew`
                    );

                    return (
                        <li
                            key={item.id}
                            onKeyDown={(e) =>
                                handleSubMenuKeyDown(e, idx, items)
                            }
                        >
                            {processedUrl.startsWith(`/`) ? (
                                <Link
                                    ref={(el) => (linksRef.current[idx] = el)}
                                    to={processedUrl}
                                    onClick={() => {
                                        onClick(item.title);
                                    }}
                                >
                                    {item.title}
                                </Link>
                            ) : (
                                <a
                                    ref={(el) => (linksRef.current[idx] = el)}
                                    href={processedUrl}
                                    onClick={() => {
                                        onClick(item.title);
                                    }}
                                >
                                    {item.title}
                                </a>
                            )}
                        </li>
                    );
                })}
        </ul>
    );
};
