import { DataNode } from 'domhandler';
import { graphql, useStaticQuery } from 'gatsby';
import parse, { Element } from 'html-react-parser';
import React from 'react';
import { IAlert, TSiteWideAlertData } from './CompanyBannerTypes';
import { SmartLink } from '../SmartLink/SmartLink';
import { isBrowser } from '../../shared/isBrowser';
import cn from 'classnames';

export const useCompanyBannerQuery = (): { nodes: IAlert[] } => {
    const { allSitewideAlertSitewideAlert: data } =
        useStaticQuery<TSiteWideAlertData>(graphql`
            query {
                allSitewideAlertSitewideAlert(
                    filter: { status: { eq: true } }
                ) {
                    nodes {
                        ...sitewide_alert__sitewide_alert
                    }
                }
            }
        `);
    return data;
};

/**
 * Checks if the current window location matches any paths provided in the paths to match string.
 * @param {string} location - the current `window.location.pathname`
 * @param {string} pathsToMatch - a comma separated list of paths to match against. Allows for `/*` wild card matching and uses `/` to match with the root path
 */
export const matchPath = (location: string, pathsToMatch: string): boolean => {
    if (!isBrowser) return false;
    if (!pathsToMatch) return true;
    const urlPatterns = pathsToMatch
        .split(`,`)
        .map((pattern) => pattern.trim());
    // Removes any trailing slashes from the current path
    const path = location?.replace(/\/+$/, ``);

    return urlPatterns.some((pattern) => {
        // Checks if the current path is part of a wild card match
        if (pattern.includes(`/*`)) {
            const partialMatch = pattern.replace(`/*`, ``);
            return path.includes(partialMatch);
        }
        // Checks for an exact match and accounts for the root path
        return pattern === path || (pattern === `/` && path === ``);
    });
};

type ParsedLinkData = {
    strong: boolean;
    emphasized: boolean;
    innerText: string;
};

/**
 * Parses the html string to replace <a> tags with <SmartLink /> components
 */
export const parseMessageHTML = (message: {
    value: string;
}): React.ReactElement =>
    parse(message?.value ?? ``, {
        replace: (domNode) => {
            if (domNode instanceof Element && domNode.name === `a`) {
                // Extracts attributes from the original anchor tag
                const href = domNode.attribs?.href;

                /**
                 * Recursively extracts `<em>`, `<strong>`, and text data from the
                 * <a> tag for rendering the Smart Link styles and text.
                 *
                 * @param domNode - The DOM element to parse (instance of DOMNode from react-html-parser).
                 * @param aggregator - The object to accumulate parsed data.
                 * @returns The updated aggregator object with parsed tag data.
                 */
                const parseImbeddedTagData = (
                    domNode: Element,
                    aggregator: ParsedLinkData
                ): ParsedLinkData => {
                    let aggregated = { ...aggregator };
                    if (!domNode) return aggregator;
                    if (domNode.children?.[0] instanceof Element) {
                        const subNode = domNode.children?.[0];
                        if (subNode.name === `em`) {
                            aggregated = {
                                ...aggregated,
                                emphasized: true,
                            };
                        }
                        if (subNode.name === `strong`) {
                            aggregated = {
                                ...aggregated,
                                strong: true,
                            };
                        }
                        return parseImbeddedTagData(subNode, aggregated);
                    } else {
                        aggregated = {
                            ...aggregated,
                            innerText: (domNode.children?.[0] as DataNode).data,
                        };
                    }
                    return aggregated;
                };

                const { emphasized, strong, innerText } = parseImbeddedTagData(
                    domNode,
                    {
                        strong: false,
                        emphasized: false,
                        innerText: ``,
                    }
                );

                const renderClasses = strong || emphasized;
                return (
                    <SmartLink
                        url={href}
                        classNames={
                            renderClasses
                                ? [
                                      `company-banner__inline-link ${cn({
                                          'company-banner__inline-link--bold':
                                              strong,
                                          'company-banner__inline-link--em':
                                              emphasized,
                                      })}`,
                                  ]
                                : undefined
                        }
                        newWindow
                    >
                        {innerText}
                    </SmartLink>
                );
            }
        },
    }) as React.ReactElement;
