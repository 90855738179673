import React, { useCallback, useState, useEffect, useRef } from 'react';
import './Header.scss';
import FocusTrapReact from 'focus-trap-react';
import logo from '../../assets/img/charter-logo-v2.svg';
import { MainMenu } from './MainMenu/MainMenu';
import { PolicyHeader } from './PolicyHeader/PolicyHeader';
import { SearchInput } from './SearchInput/SearchInput';
import { SearchResults } from '../SearchResults/SearchResults';
import { SearchTrigger } from './SearchTrigger/SearchTrigger';
import { SidebarTrigger } from './SidebarTrigger/SidebarTrigger';
import { SitesMenu } from './SitesMenu/SitesMenu';
import { useLayoutContext } from '../../providers/LayoutProvider';
import { AnalyticsController } from '../Analytics';
import { datadogRum } from '@datadog/browser-rum';
import { KeyboardKey } from '../../shared/enums/keyboardKeys.enum';
import { CompanyBanner } from '../../components/CompanyBanner/CompanyBanner';

interface Props {
    sidebarOpen: boolean;
    setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
    searchOpen: boolean;
    setSearchOpen: React.Dispatch<React.SetStateAction<boolean>>;
    pathname: string;
    onBlur?: () => void;
    searchInline?: boolean;
}

export const Header = ({
    sidebarOpen,
    setSidebarOpen,
    searchOpen,
    setSearchOpen,
    pathname,
    onBlur,
    searchInline,
}: Props) => {
    const [sitesNavExpanded, setSitesNavExpanded] = useState(false);
    const { headerRef, isPolicy, sitesNavRef } = useLayoutContext();
    const [headerHeight, setHeaderHeight] = useState(0);
    const shopSpectrumServiceButtonRef = useRef(null);

    useEffect(() => {
        if (isPolicy) {
            datadogRum.init({
                applicationId: `56f654a1-4239-4680-a706-ee0a17de842f`,
                clientToken: `pub1b43298fffc74d2ce1248542cd03901e`,
                site: `datadoghq.com`,
                service: `cew-ga`,
                env: `${process.env.GATSBY_DD_RUM_ENV_GA}`,
                sessionSampleRate: 70,
                sessionReplaySampleRate: 20,
                trackUserInteractions: true,
                trackResources: true,
                trackLongTasks: true,
                defaultPrivacyLevel: `mask-user-input`,
            });
        } else {
            datadogRum.init({
                applicationId: `75e47815-beb1-42b9-b1e8-18c19ef25b16`,
                clientToken: `pub659df4d32d0159298254b4d6f4a136b6`,
                site: `datadoghq.com`,
                service: `cew`,
                env: `${process.env.GATSBY_DD_RUM_ENV_CEW}`,
                sessionSampleRate: 70,
                sessionReplaySampleRate: 20,
                trackUserInteractions: true,
                trackResources: true,
                trackLongTasks: true,
                defaultPrivacyLevel: `mask-user-input`,
            });
        }
        datadogRum.startSessionReplayRecording();
    }, [isPolicy]);

    const handleTabOnBlur = useCallback(
        (event) => {
            const currentTarget = event.currentTarget;
            requestAnimationFrame(() => {
                if (!currentTarget.contains(document.activeElement)) {
                    setSitesNavExpanded(false);
                }
            });
        },
        [onBlur]
    );

    const handleEscape = (event: React.KeyboardEvent) => {
        if (event.key === KeyboardKey.Escape) {
            setSitesNavExpanded(false);
            shopSpectrumServiceButtonRef?.current.focus();
        }
    };

    return (
        <>
            <header
                className={`site-header ${sitesNavExpanded ? `expanded` : ``}`}
                ref={sitesNavRef}
                onBlur={handleTabOnBlur}
            >
                <div className="kite-container services-header">
                    <button
                        type="button"
                        ref={shopSpectrumServiceButtonRef}
                        onClick={() => {
                            setSitesNavExpanded(!sitesNavExpanded);
                        }}
                        onKeyDown={handleEscape}
                        aria-label={`${
                            sitesNavExpanded ? `Collapse` : `Expand`
                        } Spectrum Sites navigation section`}
                        aria-expanded={sitesNavExpanded}
                        aria-controls="spectrum-service-nav"
                    >
                        Shop Spectrum Services
                    </button>
                </div>
                <nav
                    id="spectrum-service-nav"
                    className={`sites-nav ${
                        sitesNavExpanded ? `expanded` : ``
                    }`}
                    aria-hidden={!sitesNavExpanded}
                    onKeyDown={handleEscape}
                >
                    <SitesMenu />
                </nav>
            </header>
            <div
                className={`prevent-reflow ${
                    sitesNavExpanded ? `expanded` : ``
                }`}
            ></div>
            <header
                ref={headerRef}
                className={`nav-header ${isPolicy ? `policy ` : ``}${
                    searchInline ? `search-inline` : ``
                }`}
            >
                <FocusTrapReact
                    active={searchOpen && !searchInline}
                    focusTrapOptions={{
                        allowOutsideClick: true,
                        clickOutsideDeactivates: true,
                        initialFocus: `#inputSearch`,
                        fallbackFocus: `#inputSearch`,
                    }}
                >
                    <section>
                        <div className="kite-container">
                            {!isPolicy && (
                                <SidebarTrigger
                                    sidebarOpen={sidebarOpen}
                                    setSidebarOpen={setSidebarOpen}
                                    searchOpen={searchOpen}
                                />
                            )}
                            <a
                                href="https://corporate.charter.com"
                                rel="home"
                                aria-hidden={!isPolicy && searchOpen}
                                className={
                                    !isPolicy && searchOpen ? `hidden` : ``
                                }
                                onClick={() => {
                                    AnalyticsController.track({
                                        event: `global header click`,
                                        globalHeaderLink: `charter logo`,
                                    });
                                }}
                            >
                                <img
                                    src={logo}
                                    alt="Charter Communications logo"
                                />
                            </a>
                            {!(!isPolicy && searchOpen) && <MainMenu />}
                            {!isPolicy && !searchOpen && (
                                <SearchTrigger setSearchOpen={setSearchOpen} />
                            )}
                            {!isPolicy && searchOpen && (
                                <SearchInput
                                    searchOpen={searchOpen}
                                    setSearchOpen={setSearchOpen}
                                />
                            )}
                        </div>
                        {!isPolicy && searchOpen && (
                            <SearchResults setSearchOpen={setSearchOpen} />
                        )}
                    </section>
                </FocusTrapReact>
            </header>
            {isPolicy && (
                <PolicyHeader
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                    searchOpen={searchOpen}
                    setSearchOpen={setSearchOpen}
                    searchInline={searchInline}
                    refHeaderHeight={headerHeight}
                />
            )}
            <CompanyBanner pathname={pathname} />
        </>
    );
};
